import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,5,6,7,8,9,10];

export const dictionary = {
		"/(app)": [~14,[2]],
		"/(app)/admin": [~16,[2,4]],
		"/(app)/admin/customers": [~17,[2,4]],
		"/(app)/admin/customers/new": [~22,[2,4]],
		"/(app)/admin/customers/[straikerId]/edit": [~18,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users": [~19,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users/new": [~21,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users/[email]/edit": [~20,[2,4,5]],
		"/(app)/applications": [23,[2,6]],
		"/(app)/applications/new": [33,[2,6]],
		"/(app)/applications/[id]": [~24,[2,7]],
		"/(app)/applications/[id]/detections": [25,[2,7]],
		"/(app)/applications/[id]/models": [26,[2,7]],
		"/(app)/applications/[id]/prompts": [27,[2,7,8]],
		"/(app)/applications/[id]/prompts/[user_id]": [~28,[2,7,8,9]],
		"/(app)/applications/[id]/prompts/[user_id]/[turn_id]": [~29,[2,7,8,9]],
		"/(app)/applications/[id]/sources": [30,[2,7]],
		"/(app)/applications/[id]/verdicts": [~31,[2,6,10]],
		"/(app)/applications/[id]/verdicts/[turn_id]": [~32,[2,6,10]],
		"/(unprotected)/forgot-password": [~49,[13]],
		"/(app)/kitchen-sink": [34,[2,11]],
		"/(app)/kitchen-sink/badge": [35,[2,11]],
		"/(app)/kitchen-sink/button": [36,[2,11]],
		"/(app)/kitchen-sink/card": [37,[2,11]],
		"/(app)/kitchen-sink/hex-grid": [38,[2,11]],
		"/(app)/kitchen-sink/hexagon": [39,[2,11]],
		"/(app)/kitchen-sink/logo": [40,[2,11]],
		"/(app)/kitchen-sink/switch": [41,[2,11]],
		"/(app)/kitchen-sink/tag": [42,[2,11]],
		"/(unprotected)/login": [~50,[13]],
		"/(unprotected)/logout": [51,[13]],
		"/(app)/profile": [43,[2]],
		"/(app)/settings": [~44,[2,12]],
		"/(app)/settings/detections": [~45,[2,12]],
		"/(app)/settings/users": [~46,[2,12]],
		"/(app)/settings/users/new": [~48,[2,12]],
		"/(app)/settings/users/[email]/edit": [~47,[2,12]],
		"/(app)/[...catchall]": [~15,[2],[,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';